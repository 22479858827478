import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { spacing, color } from '../../Layouts/theme';
import { Row, Col } from 'react-bootstrap';
import { BODY_6_TH, BODY_6_EN } from '../../Layouts/styled/main';
import i18next from 'i18next';

const BUTTON_STYLE: CSSProperties = {
  width: '100%',
  backgroundColor: color.backgroundPrimary,
  color: 'black',
  border: 0,
  borderRadius: 0,
  padding: `6px ${spacing[0]}px`,
  textAlign: 'left',
};

interface SymptomListProps {
  symptomList?: Array<any>;
  endOfProduct?: number;
  symptomIdActive?: number;
  onToggle: (data: any) => void;
}

const SymptomList: React.FC<SymptomListProps> = ({
  symptomList,
  endOfProduct,
  symptomIdActive,
  onToggle,
}) => {
  return (
    <Col md={endOfProduct === 2 ? 12 : 3}>
      {symptomList?.map((symptom, index) => {
        const isActive = symptomIdActive === symptom.id;
        return (
          <div key={'gas-' + symptom.id}>
            <button
              style={{ ...BUTTON_STYLE, paddingTop: 6 }}
              onClick={() => onToggle(symptom)}
            >
              <span
                style={{
                  ...(i18next?.language === 'th' ? BODY_6_TH : BODY_6_EN),
                  fontFamily: isActive
                    ? i18next?.language === 'th'
                      ? 'KanitMedium'
                      : 'RobotaBold'
                    : i18next?.language === 'th'
                      ? 'KanitLight'
                      : 'RobotaRegular',
                  color: isActive ? color.mainColor2 : color.textBlack,
                }}
              >
                {symptom[`symptom_1_${i18next.language}`]}
              </span>
            </button>
          </div>
        );
      })}
    </Col>
  );
};

export default SymptomList;
