import i18next from 'i18next';
import React, { CSSProperties, Fragment } from 'react';
import { Col, ColProps } from 'react-bootstrap';
import styled from 'styled-components';

import { spacing, color } from '../../Layouts/theme';
import { BODY_6 } from '../../Layouts/styled/main';

const BUTTON_STYLE: CSSProperties = {
  width: '100%',
  backgroundColor: color.backgroundPrimary,
  color: 'black',
  border: 0,
  borderRadius: 0,
  padding: `6px ${spacing[0]}`,
  textAlign: 'left',
};

export interface SystemItemsProps {
  id: number;
  nameTH: string;
  nameEN: string;
  imageName?: string;
}

interface SystemButtonProps {
  items: Array<SystemItemsProps>;
  isActived?: boolean;
  menuActive?: number;
  dimension?: ColProps;
  screenSize: 'mb' | 'dt';
  onToggle: (data: Partial<SystemItemsProps>, ...args: any) => void;
}

const Box = styled.div<{ border: boolean }>`
  border-bottom: ${(props) =>
    props.border ? `1.5px solid ${color.mainColor5}` : 'none'};
  display: flex;
  align-items: center;
  height: 100%;
`;

const SystemButton: React.FC<SystemButtonProps> = ({
  items,
  isActived = true,
  menuActive,
  screenSize,
  onToggle,
}) => {
  const count = screenSize === 'mb' ? 1 : 2;
  return (
    <Fragment key="system-button">
      {items?.map((item: SystemItemsProps, index: number) => {
        const isActive = menuActive === item.id && isActived;
        let fontFamily: string;
        if (isActive) {
          fontFamily =
            i18next?.language === 'th' ? 'KanitMedium' : 'RobotaBold';
        } else {
          fontFamily =
            i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular';
        }
        return (
          <Col xs="12" md="6" key={`system-body-${item.id}`}>
            <Box border={index < items.length - count}>
              <button
                type="button"
                style={{
                  ...BUTTON_STYLE,
                  ...BODY_6,
                  padding: '10px 0px',
                  color: isActive ? color.mainColor5 : 'black',
                  fontFamily,
                }}
                onClick={() => onToggle(item)}
              >
                {i18next.language === 'th' ? item.nameTH : item.nameEN}
              </button>
            </Box>
          </Col>
        );
      })}
    </Fragment>
  );
};

export default SystemButton;
