import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Parallax, Background } from 'react-parallax';
import { spacing, color, palette } from '../../Layouts/theme';
import { Row, Col } from 'react-bootstrap';
import {
  BODY_3_TH,
  BODY_3_EN,
  BODY_7_TH,
  BODY_7_EN,
} from '../../Layouts/styled/main';
import i18next from 'i18next';
import { CardProduct } from '../card-product/card-product';
import { Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { arrows_slim_right } from 'react-icons-kit/linea/arrows_slim_right';
import SymptomList from './symptom-list';
import { getSourceImage } from "../../utils/image-sharp";
import { CardCaption } from '../card-caption/card-caption';

const LINE: CSSProperties = {
  borderBottom: `1.5px solid ${color.mainColor4}`,
};

const LINK_TO: CSSProperties = {
  textDecoration: 'none',
  color: color.textBlack,
};

const ARROW: CSSProperties = {
  color: color.mainColor4,
  position: 'absolute',
  bottom: 0,
  right: 15,
  zIndex: 999,
};

const PRODUCT_DESCRIPTION: CSSProperties = {
  paddingTop: spacing[3],
};

const BOTTOM_DESCRIPTION: CSSProperties = {
  display: 'flex',
  alignItems: 'flex-end',
};

interface GastrointestinalSystemProps {
  // symptomId?: number,
  symptomList?: Array<any>;
  relateProducts?: Array<any>;
  endOfProduct?: number;
  symptomIdActive?: number;
  data?: any;
  onToggle?: (data: any) => void;
}

const GastrointestinalSystem: React.FC<GastrointestinalSystemProps> = ({
  symptomList,
  relateProducts,
  endOfProduct,
  symptomIdActive,
  onToggle,
  data,
}) => {

  return (
    <div>
      <Row style={{ paddingTop: endOfProduct <= 2 ? 0 : spacing[7] }}>
        {endOfProduct >= 3 && (
          <SymptomList
            symptomList={symptomList}
            endOfProduct={endOfProduct}
            symptomIdActive={symptomIdActive}
            onToggle={onToggle}
          />
        )}

        <Col md={endOfProduct === 2 ? 12 : 9}>
          {relateProducts
            ?.slice(0, endOfProduct)
            .map((products: any, index: number) => {
              return (
                <Row
                  key={'gas-row-' + index}
                  style={{
                    // ...LINE,
                    paddingTop: index === 0 ? 0 : spacing[6],
                    paddingBottom: endOfProduct === 2 ? spacing[5] : spacing[6],
                  }}
                  data-aos={'fade-up'}
                >
                  {products?.map((product: any, i: number) => {
                    return (
                      <Col key={'gas-col-' + i} xs={6} md={4}>
                        <Link
                          to={`/product/${product.product_code}`}
                          style={LINK_TO}
                          state={{}}
                        >
                          <CardCaption
                            source={getSourceImage(product?.product_image_path_1 ? product.product_image_path_1 : 'no-img.png', data)}
                            containerStyle={{
                              borderRadius:
                                endOfProduct <= 2 ? '40px 0px' : '80px 0px',
                              background: product?.theme_code
                                ? `linear-gradient(180deg, ${palette.productSet1[
                                `${product.theme_code}1`
                                ]
                                } 60%, ${palette.productSet1[
                                `${product.theme_code}2`
                                ]
                                } 40%)`
                                : 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
                            }}
                            disableAos
                            topic={product[`product_name_${i18next.language}`]}
                            content={product[`details_paragraph_1_${i18next.language}`]}
                            topicStyle={{
                              ...(i18next.language === 'th'
                                ? BODY_3_TH
                                : BODY_3_EN),
                              marginBottom:
                                endOfProduct <= 2 ? spacing[1] : 6,
                            }}
                            contentStyle={{
                              flex: 1,
                              paddingBottom: 5,
                              ...(i18next.language === 'th'
                                ? BODY_7_TH
                                : BODY_7_EN),
                            }}
                          />
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
        </Col>

        <Col
          md={{
            span: 12,
            offset: 0,
          }}
        >
          <Row style={{ ...LINE, paddingBottom: spacing[3] }} />
        </Col>
      </Row>
    </div>
  );
};

export default GastrointestinalSystem;
