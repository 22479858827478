// symptom_id
// body_system_th = ระบบร่างกาย (TH)
// body_system_en = ระบบร่างกาย (EN)
// symptom_1_th = อาการ-1 (TH)
// symptom_1_en = อาการ-1 (EN)
// symptom_2_th = อาการ-2 (TH)
// symptom_2_en = อาการ-2 (EN)
// related_product_code_1 = รหัสสินค้าเกี่ยวข้อง 1
// related_product_code_2 = รหัสสินค้าเกี่ยวข้อง 2
// related_product_code_3 = รหัสสินค้าเกี่ยวข้อง 3
// related_product_code_4 = รหัสสินค้าเกี่ยวข้อง 4
// medicine_name_en = ชื่อยา (EN)

export const symptoms = [
  {
    id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    image_name: 'Asset_body_2.svg',
  },
  {
    id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    image_name: 'Asset_body_3.svg',
  },
  {
    id: 3,
    body_system_th: 'ทางเดินปัสสาวะ',
    body_system_en: 'Urinary Tract',
    image_name: 'Asset_body_6.svg',
  },
  {
    id: 4,
    body_system_th: 'กล้ามเนื้อ กระดูก',
    body_system_en: 'Mascular and bone',
    image_name: 'Asset_body_14.svg',
  },
  {
    id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    image_name: 'Asset_body_11.svg',
  },
  {
    id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    image_name: 'Asset_body_9.svg',
  },
  {
    id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    image_name: 'Asset_body_13.svg',
  },
  {
    id: 8,
    body_system_th: 'บำรุงสุขภาพบุรุษ',
    body_system_en: "Men's health",
    image_name: 'Asset_body_7.svg',
  },
  {
    id: 9,
    body_system_th: 'ควบคุมน้ำหนัก',
    body_system_en: 'Control weight',
    image_name: 'Asset_body_5.svg',
  },
  {
    id: 10,
    body_system_th: 'บำรุงสมอง',
    body_system_en: 'Brain health',
    image_name: 'Asset_body_8.svg',
  },
  {
    id: 11,
    body_system_th: 'ปัญหาดวงตา',
    body_system_en: 'Eye health',
    image_name: 'Asset_body_11.svg',
  },
  {
    id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    image_name: 'Asset_body_12.svg',
  },
  {
    id: 13,
    body_system_th: 'ลดสารพิษในร่างกาย',
    body_system_en: 'Detoxification',
    image_name: 'Asset_body_11.svg',
  },
  {
    id: 14,
    body_system_th: 'บำรุงตับ',
    body_system_en: 'Liver care',
    image_name: 'Asset_body_4.svg',
  },
];

export const symptomDetail = [
  {
    id: 1,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไข้ ร้อนใน กระหายน้ำ',
    symptom_1_en: 'Antipyretic,Relieve flu-like symptoms, body heat relief',
    symptom_2_th: 'ลดไข้ ร้อนใน กระหายน้ำ ทั่วไป',
    symptom_2_en: 'Antipyretic',
    related_product_code_1: '4007',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 2,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไข้ ร้อนใน กระหายน้ำ',
    symptom_1_en: 'Antipyretic,Relieve flu-like symptoms, body heat relief',
    symptom_2_th:
      'แก้โรคที่มีการอักเสบและติดเชื้อ เช่น มีไข้ ไอเจ็บคอ ไซนัส ทอนซิลอักเสบ',
    symptom_2_en: 'Reduce infection and inflammation in respiratory system',
    related_product_code_1: '4010',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 3,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไข้ ร้อนใน กระหายน้ำ',
    symptom_1_en: 'Antipyretic,Relieve flu-like symptoms, body heat relief',
    symptom_2_th:
      'แก้ไข้ หรือผู้ที่มีปัญหาตับร่วมด้วย เช่น ไวรัสตับอักเสบบี เบาหวาน ไขมันพอกตับ',
    symptom_2_en: 'Relieve fever, for those with liver problems',
    related_product_code_1: '2007',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 4,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไข้ ร้อนใน กระหายน้ำ',
    symptom_1_en: 'Antipyretic,Relieve flu-like symptoms, body heat relief',
    symptom_2_th: 'แก้ร้อนในกระหายน้ำหรือมีไข้ต่ำๆ',
    symptom_2_en: 'Reduce internal body heat, relieve mild fever',
    related_product_code_1: '2045',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 5,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไข้ ร้อนใน กระหายน้ำ',
    symptom_1_en: 'Antipyretic,Relieve flu-like symptoms, body heat relief',
    symptom_2_th: 'แก้ร้อนในกระหายน้ำหรือมีไข้ต่ำๆในเด็ก',
    symptom_2_en: 'Reduce internal body heat, relieve mild fever',
    related_product_code_1: '4007',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 6,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไอ เจ็บคอ',
    symptom_1_en: 'For relieving cough and sore throat',
    symptom_2_th: 'แก้ไอแห้ง คันคอ คอแห้ง หรือมีเสมหะข้นเหนียว',
    symptom_2_en: 'Reliever dry cough',
    related_product_code_1: '4006',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 7,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไอ เจ็บคอ',
    symptom_1_en: 'For relieving cough and sore throat',
    symptom_2_th: 'แก้ไอแห้งหรือมีเสมหะข้นเหนียว หรือมีไข้ร่วมด้วย',
    symptom_2_en: 'Relieve cough due to fever',
    related_product_code_1: '4005',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 8,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไอ เจ็บคอ',
    symptom_1_en: 'For relieving cough and sore throat',
    symptom_2_th: 'แก้ไอแห้งหรือมีเสมหะข้นเหนียว หรือมีไข้ร่วมด้วย',
    symptom_2_en: 'Relieve cough due to fever',
    related_product_code_1: '4006',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 9,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไอ เจ็บคอ',
    symptom_1_en: 'For relieving cough and sore throat',
    symptom_2_th: 'แก้ไอแบบมีเสมหะ และไม่มีเสมหะ ใช้ในเด็ก',
    symptom_2_en: 'Relieve cough with phlegm',
    related_product_code_1: '3005',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 10,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไอ เจ็บคอ',
    symptom_1_en: 'For relieving cough and sore throat',
    symptom_2_th: 'บรรเทาอาการไอ เจ็บคอ คันคอ คอแห้ง หรือมีเสมหะทั่วไป',
    symptom_2_en: 'Relieve cough, phlegm, and expectorant',
    related_product_code_1: '3001',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 11,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไอ เจ็บคอ',
    symptom_1_en: 'For relieving cough and sore throat',
    symptom_2_th: 'บรรเทาอาการไอ เจ็บคอ คันคอ คอแห้ง หรือมีเสมหะทั่วไป',
    symptom_2_en: 'Relieve cough, phlegm, and expectorant',
    related_product_code_1: '1001',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 12,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไอ เจ็บคอ',
    symptom_1_en: 'For relieving cough and sore throat',
    symptom_2_th: 'ยาตำรับจีนโบราณ  บรรเทาอาการไอ หืดหอบ',
    symptom_2_en: 'Relieve cough and difficulty of breathing',
    related_product_code_1: '5012',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 13,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไอ เจ็บคอ',
    symptom_1_en: 'For relieving cough and sore throat',
    symptom_2_th: 'แก้เจ็บคอ ปากเป็นแผล  ลิ้นเป็นแผล',
    symptom_2_en: 'Relieve of oral ulcers',
    related_product_code_1: '6002',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 14,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไอ เจ็บคอ',
    symptom_1_en: 'For relieving cough and sore throat',
    symptom_2_th: 'เป็นยาผงแก้ลิ้นฝ้าขาว ปากเปื่อย ลิ้นเปื่อย',
    symptom_2_en: 'Relieve of oral ulcers',
    related_product_code_1: '6003',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 15,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'แก้ไอ เจ็บคอ',
    symptom_1_en: 'For relieving cough and sore throat',
    symptom_2_th:
      'แก้ไอแบบอักเสบและติดเชื้อ เจ็บคอ หรือมีไข้ร่วมด้วย เนื่องจากสามารถลดอาการอักเสบและยับยั้งการเจริญเติบโตของแบคทีเรียและไวรัสได้หลายชนิด',
    symptom_2_en: 'Relieve cough with inflammation or infection',
    related_product_code_1: '4010',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 16,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'บรรเทาอาการจากริดสีดวงจมูก',
    symptom_1_en: 'Sinus',
    symptom_2_th: 'บรรเทาริดสีดวงจมูก',
    symptom_2_en: 'Sinus reliever',
    related_product_code_1: '5011',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 17,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'ภูมิแพ้น้ำมูกไหล มีเสมหะ',
    symptom_1_en: 'runny nose, with phlegm',
    symptom_2_th: 'ลดน้ำมูกและเสมหะที่มีไข้หรือมีการอักเสบร่วมด้วย',
    symptom_2_en: 'Relieve runny nose and phlegm, with fever or inflammation',
    related_product_code_1: '4010',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 18,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'ภูมิแพ้น้ำมูกไหล มีเสมหะ',
    symptom_1_en: 'runny nose',
    symptom_2_th: 'แก้อาการภูมิแพ้ทางเดินหายใจ',
    symptom_2_en: 'Reduce symptoms of respiratory allergies',
    related_product_code_1: '5011',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 19,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'ภูมิแพ้น้ำมูกไหล มีเสมหะ',
    symptom_1_en: 'runny nose, with phlegm',
    symptom_2_th:
      'แก้อาการภูมิแพ้ทางเดินหายใจ และภูมิแพ้ที่เกิดขึ้นบริเวณผิวหนัง',
    symptom_2_en: 'Reduce symptoms of respiratory allergies',
    related_product_code_1: '2008',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 20,
    symptom_id: 1,
    body_system_th: 'ทางเดินหายใจ',
    body_system_en: 'Respiratory',
    symptom_1_th: 'ภูมิแพ้น้ำมูกไหล มีเสมหะ',
    symptom_1_en: 'runny nose, with phlegm',
    symptom_2_th: 'มีน้ำมูกและเสมหะบ่อย แต่ไม่มีไข้',
    symptom_2_en: 'Relieve runny nose and phlegm without fever',
    related_product_code_1: '2003',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 21,
    symptom_id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    symptom_1_th: 'ผื่นคันตามผิวหนัง',
    symptom_1_en: 'rash',
    symptom_2_th:
      'แก้ผื่นคันตามผิวหนัง แก้เลือดและน้ำเหลืองเสียในเด็ก และเสริมภูมิคุ้มกันให้แข็งแรงขึ้น',
    symptom_2_en: 'Relieve eczema. Can be used by children',
    related_product_code_1: '2039',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 22,
    symptom_id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    symptom_1_th: 'ผื่นคันตามผิวหนัง',
    symptom_1_en: 'rash',
    symptom_2_th:
      'แก้โรคผื่น ผิวหนังอักเสบ เริม งูสวัด จะเหมาะกับผู้ใหญ่หรือโรคผิวหนังที่รุนแรงปานกลาง',
    symptom_2_en: 'Relieve rashes from herpes or other skin viruses',
    related_product_code_1: '2036',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 23,
    symptom_id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    symptom_1_th: 'ผื่นคันตามผิวหนัง',
    symptom_1_en: 'rash',
    symptom_2_th: 'อาการผื่นแพ้จากการสัมผัสหรือแพ้อาหาร',
    symptom_2_en: 'Reduce rashes from allergies',
    related_product_code_1: '2008',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 24,
    symptom_id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    symptom_1_th: 'ผื่นคันตามผิวหนัง',
    symptom_1_en: 'rash',
    symptom_2_th: 'แก้อาการโรคผิวหนังด้วยยาจีนแผนโบราณ',
    symptom_2_en: 'Relieve eczema',
    related_product_code_1: '5010',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 25,
    symptom_id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    symptom_1_th: 'ผื่นคันตามผิวหนัง',
    symptom_1_en: 'rash',
    symptom_2_th: 'แก้อาการโรคผิวหนัง',
    symptom_2_en: 'Relieve eczema',
    related_product_code_1: '2038',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 26,
    symptom_id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    symptom_1_th: 'ผิวหมองคล้ำเสีย',
    symptom_1_en: 'Darkened skin',
    symptom_2_th:
      'ช่วยชะลอวัย ต้านอนุมูลอิสระ ช่วยให้เซลล์ผิวหนังแข็งแรง กระชับ ลดริ้วรอยและฝ้า',
    symptom_2_en: 'Antioxidants, maintain skin health',
    related_product_code_1: '4017',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 27,
    symptom_id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    symptom_1_th: 'ผิวหมองคล้ำเสีย',
    symptom_1_en: 'Darkened skin',
    symptom_2_th: 'เพิ่มความกระชับ ลดเลือนริ้วรอย ทำให้ผิวหนังเรียบตึง',
    symptom_2_en: 'Tighten skin, reduce wrinkles',
    related_product_code_1: '2050',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 28,
    symptom_id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    symptom_1_th: 'ผิวหมองคล้ำเสีย',
    symptom_1_en: 'Darkened skin',
    symptom_2_th:
      'ช่วยชะลอวัย ต้านอนุมูลอิสระ ช่วยให้เซลล์ผิวหนังแข็งแรง กระชับ ลดริ้วรอยและฝ้า',
    symptom_2_en: 'Antioxidants, maintain skin health',
    related_product_code_1: '2041',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 29,
    symptom_id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    symptom_1_th: 'ผิวหมองคล้ำเสีย',
    symptom_1_en: 'Darkened skin',
    symptom_2_th:
      'ผิวพรรณขาวกระจ่างสดใส ช่วยรักษาผิวแห้ง ผิวไหม้เกรียมจากแดด คงความชุ่มชื้นให้ผิว',
    symptom_2_en: 'Maintain facial moisture',
    related_product_code_1: '2035',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 30,
    symptom_id: 6,
    body_system_th: 'ผิวหนัง',
    body_system_en: 'Skin',
    symptom_1_th: 'ผิวหมองคล้ำเสีย',
    symptom_1_en: 'Darkened skin',
    symptom_2_th:
      'ผิวพรรณขาวกระจ่างสดใส ช่วยรักษาผิวแห้ง ผิวไหม้เกรียมจากแดด คงความชุ่มชื้นให้ผิว',
    symptom_2_en: 'Maintain facial moisture',
    related_product_code_1: '2034',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 31,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'ท้องอืด ท้องเฟ้อ อาหารไม่ย่อย',
    symptom_1_en: 'Flatulence',
    symptom_2_th: 'แก้ท้องอืดท้องเฟ้อหรืออาหารไม่ย่อย มีอาการจุกท้อง จุกอก',
    symptom_2_en: 'Relieve flatulence',
    related_product_code_1: '4009',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 32,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'ท้องอืด ท้องเฟ้อ อาหารไม่ย่อย',
    symptom_1_en: 'Flatulence',
    symptom_2_th:
      'แก้ท้องอืดท้องเฟ้อหรืออาหารไม่ย่อย คลื่นไส้ อาเจียน สำหรับผู้ที่เป็นไม่มาก ไม่เป็นเรื้อรัง และมีอาการอ่อนเพลีย',
    symptom_2_en: 'Reduce flatulence and nausea symptoms',
    related_product_code_1: '2027',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 33,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'ท้องอืด ท้องเฟ้อ อาหารไม่ย่อย',
    symptom_1_en: 'Flatulence',
    symptom_2_th:
      'แก้ท้องอืดท้องเฟ้อหรืออาหารไม่ย่อย คลื่นไส้ อาเจียน สำหรับผู้ที่เป็นไม่มาก ไม่เป็นเรื้อรัง',
    symptom_2_en: 'Reduce flatulence and nausea symptoms',
    related_product_code_1: '3004',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 34,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'ท้องอืด ท้องเฟ้อ อาหารไม่ย่อย',
    symptom_1_en: 'Flatulence',
    symptom_2_th:
      'แก้ท้องอืดท้องเฟ้อหรืออาหารไม่ย่อย เนื่องจากช่วยกระตุ้นระบบเผาผลาญให้ไฟย่อยทำงานได้ดีขึ้น',
    symptom_2_en: 'Relieve flatulence and indigestion',
    related_product_code_1: '4003',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 35,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'ท้องอืด ท้องเฟ้อ อาหารไม่ย่อย',
    symptom_1_en: 'Flatulence',
    symptom_2_th:
      'แก้ท้องอืดท้องเฟ้อหรืออาหารไม่ย่อย แก้ลมจุกเสียดที่จุกท้อง จุกอก',
    symptom_2_en: 'Relieves flatulence.',
    related_product_code_1: '2031',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 36,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'บรรเทาอาการจากแผลในกระเพาะอาหาร',
    symptom_1_en: 'Stomach ulcers',
    symptom_2_th:
      'ช่วยกระตุ้นการหลั่งเมือกมาเคลือบแผลในกระเพาะอาหารได้ดี ต้านเชื้อH.pyloriที่ทำให้เกิดแผลในกระเพาะ',
    symptom_2_en: 'Stimulate mucus to coat the stomach. Relieve stomuch ulcer',
    related_product_code_1: '4009',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 37,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'บรรเทาอาการจากแผลในกระเพาะอาหาร',
    symptom_1_en: 'Stomach ulcers',
    symptom_2_th:
      'ช่วยกระตุ้นการหลั่งเมือกมาเคลือบแผลในกระเพาะอาหารได้ดี ต้านเชื้อH.pyloriที่ทำให้เกิดแผลในกระเพาะ',
    symptom_2_en: 'Stimulate mucus to coat the stomach. Relieve stomuch ulcer',
    related_product_code_1: '4003',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 38,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'ท้องผูก',
    symptom_1_en: 'Constipation',
    symptom_2_th: 'กระตุ้นการบีบตัวของลำไส้ให้ขับอุจจาระ',
    symptom_2_en: 'Relieve constipation',
    related_product_code_1: '4011',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 39,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'ท้องผูก',
    symptom_1_en: 'Constipation',
    symptom_2_th: 'กระตุ้นการบีบตัวของลำไส้ให้ขับอุจจาระและเพิ่มกากใยอาหาร',
    symptom_2_en: 'Improve large intestine functions, relieve constipation',
    related_product_code_1: '5009',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 40,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'ท้องผูก',
    symptom_1_en: 'Constipation',
    symptom_2_th:
      'เหมาะสำหรับผู้ที่รับประทานพืชผักน้อย กากอาหารออกมาน้อย ไม่ค่อยขับถ่าย เบื่ออาหาร',
    symptom_2_en: 'For those who consume insufficient dietary fiber',
    related_product_code_1: '2056',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 41,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'บรรเทาอาการจากริดสีดวง',
    symptom_1_en: 'Hemorrhoids',
    symptom_2_th:
      'แก้ริดสีดวงทุกระยะทั้งภายในและภายนอกเหมาะสำหรับคนที่เป็นไม่มาก ติ่งเนื้อยังเล็ก',
    symptom_2_en: 'Relieve mild hemorrhoids',
    related_product_code_1: '2058',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 42,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'บรรเทาอาการจากริดสีดวง',
    symptom_1_en: 'Hemorrhoids',
    symptom_2_th:
      'ยาลูกกลอนสูตรดั้งเดิม บรรเทาอาการริดสีดวงทวารหนัก แก้ท้องผูก เป็นยาระบายอ่อนๆ',
    symptom_2_en: 'Relieve hemorrhoids, and mild constipation',
    related_product_code_1: '5009',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 43,
    symptom_id: 2,
    body_system_th: 'ทางเดินอาหาร',
    body_system_en: 'Gastrointestinal',
    symptom_1_th: 'บรรเทาอาการจากริดสีดวง',
    symptom_1_en: 'Hemorrhoids',
    symptom_2_th:
      'แก้ริดสีดวงทุกระยะทั้งภายในและภายนอก เหมาะสำหรับคนที่เป็นมาก ติ่งเนื้อใหญ่ มีการอักเสบมากและท้องผูก',
    symptom_2_en: 'Relieve hemorrhoids of all stages',
    related_product_code_1: '4002',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 44,
    symptom_id: 3,
    body_system_th: 'ทางเดินปัสสาวะ',
    body_system_en: 'Urinary Tract',
    symptom_1_th: 'บรรเทาอาการจากนิ่ว',
    symptom_1_en: 'kidney stones',
    symptom_2_th: 'มีนิ่วกรดยูริคในไตและท่อไต',
    symptom_2_en: 'Reduce uric acid stones in kidney',
    related_product_code_1: '2055',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 45,
    symptom_id: 3,
    body_system_th: 'ทางเดินปัสสาวะ',
    body_system_en: 'Urinary Tract',
    symptom_1_th: 'บรรเทาอาการจากนิ่ว',
    symptom_1_en: 'kidney stones',
    symptom_2_th:
      'นิ่วกรดในไตและท่อไตขนาดไม่เกิน2mm ทำให้ปัสสาวะใสขึ้น เนื่องจากมีฤทธิ์ทำให้ปัสสาวะเป็นด่าง',
    symptom_2_en: 'Dissolve kidney stones, clear urination',
    related_product_code_1: '2055',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 46,
    symptom_id: 3,
    body_system_th: 'ทางเดินปัสสาวะ',
    body_system_en: 'Urinary Tract',
    symptom_1_th: 'ต่อมลูกหมากโต',
    symptom_1_en: 'Enlarged prostate',
    symptom_2_th: 'ป้องกันความเสี่ยงต่อมลูกหมากโต',
    symptom_2_en: 'Reduce risk of enlarged prostate',
    related_product_code_1: '2012',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 47,
    symptom_id: 3,
    body_system_th: 'ทางเดินปัสสาวะ',
    body_system_en: 'Urinary Tract',
    symptom_1_th: 'บรรเทาอาการจากปัสสาวะอักเสบ',
    symptom_1_en: 'Urinary tract infection',
    symptom_2_th:
      'ยับยั้งการเจริญเติบโตของเชื้อแบคทีเรียที่ทำให้เกิดกระเพาะปัสสาวะอักเสบ ลดอัตราการติดเชื้อในทางเดินปัสสาวะ',
    symptom_2_en:
      'Slow down growth of urinary tract bacteria, thus reduce risk of urinary tract infection',
    related_product_code_1: '2054',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 48,
    symptom_id: 4,
    body_system_th: 'กล้ามเนื้อ กระดูก',
    body_system_en: 'Mascular and bone',
    symptom_1_th: 'กล้ามเนื้อ',
    symptom_1_en: 'Muscle',
    symptom_2_th: 'กล้ามเนื้ออักเสบ ปวดเมื่อย',
    symptom_2_en: 'Relieve muscle ache',
    related_product_code_1: '4013',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 49,
    symptom_id: 4,
    body_system_th: 'กล้ามเนื้อ กระดูก',
    body_system_en: 'Mascular and bone',
    symptom_1_th: 'กล้ามเนื้อ',
    symptom_1_en: 'Muscle',
    symptom_2_th: 'กล้ามเนื้ออักเสบ ปวดเมื่อย',
    symptom_2_en: 'Relieve muscle ache',
    related_product_code_1: '4012',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 50,
    symptom_id: 4,
    body_system_th: 'กล้ามเนื้อ กระดูก',
    body_system_en: 'Mascular and bone',
    symptom_1_th: 'กล้ามเนื้อ',
    symptom_1_en: 'Muscle',
    symptom_2_th: 'กล้ามเนื้ออักเสบ ปวดเมื่อย',
    symptom_2_en: 'Relieve muscle ache',
    related_product_code_1: '2053',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 51,
    symptom_id: 4,
    body_system_th: 'กล้ามเนื้อ กระดูก',
    body_system_en: 'Mascular and bone',
    symptom_1_th: 'กล้ามเนื้อ',
    symptom_1_en: 'Muscle',
    symptom_2_th: 'กล้ามเนื้ออักเสบ ปวดเมื่อย',
    symptom_2_en: 'Relieve muscle ache',
    related_product_code_1: '2052',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 52,
    symptom_id: 4,
    body_system_th: 'กล้ามเนื้อ กระดูก',
    body_system_en: 'Mascular and bone',
    symptom_1_th: 'กล้ามเนื้อ',
    symptom_1_en: 'Muscle',
    symptom_2_th: 'ปวดเมื่อย',
    symptom_2_en: 'Relieve muscle ache',
    related_product_code_1: '2011',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 53,
    symptom_id: 4,
    body_system_th: 'กล้ามเนื้อ กระดูก',
    body_system_en: 'Mascular and bone',
    symptom_1_th: 'ข้อกระดูก',
    symptom_1_en: 'Bone and joint',
    symptom_2_th: 'ปวดข้อปวดกระดูก บำรุงน้ำไขข้อ',
    symptom_2_en: 'Relieve joint pain,',
    related_product_code_1: '2049',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 54,
    symptom_id: 4,
    body_system_th: 'กล้ามเนื้อ กระดูก',
    body_system_en: 'Mascular and bone',
    symptom_1_th: 'ข้อกระดูก',
    symptom_1_en: 'Bone and joint',
    symptom_2_th: 'ปวดข้อปวดกระดูก บำรุงน้ำไขข้อ',
    symptom_2_en: 'Relieve joint pain,',
    related_product_code_1: '2053',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 55,
    symptom_id: 4,
    body_system_th: 'กล้ามเนื้อ กระดูก',
    body_system_en: 'Mascular and bone',
    symptom_1_th: 'ข้อกระดูก',
    symptom_1_en: 'Bone and joint',
    symptom_2_th: 'เสริมกระดูก',
    symptom_2_en: 'Increase cell generations in joints and bones',
    related_product_code_1: '2051',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 56,
    symptom_id: 4,
    body_system_th: 'กล้ามเนื้อ กระดูก',
    body_system_en: 'Mascular and bone',
    symptom_1_th: 'ข้อกระดูก',
    symptom_1_en: 'Bone and joint',
    symptom_2_th: 'ใช้ในผู้ป่วยปวดเก๊าท์ รูมาตอยด์',
    symptom_2_en: 'For those with gaut, and rhumatoids',
    related_product_code_1: '2049',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 57,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'ความดันโลหิตสูง',
    symptom_1_en: 'High Blood pressure',
    symptom_2_th:
      'ความดันโลหิตสูงทำให้เลือดไหลเวียนไปเลี้ยงเส้นเลือดฝอยได้ดียิ่งขึ้น',
    symptom_2_en: 'Improves blood circulation, and reduce blood pressure',
    related_product_code_1: '2048',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 58,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'ความดันโลหิตสูง',
    symptom_1_en: 'High Blood pressure',
    symptom_2_th: 'ความดันโลหิตสูงไม่มากหรือต้องการควบคุมไม่ให้สูง',
    symptom_2_en: 'For those with slightly high blood pressure',
    related_product_code_1: '2047',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 59,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'ความดันโลหิตสูง',
    symptom_1_en: 'High Blood pressure',
    symptom_2_th:
      'ความดันโลหิตสูงในคนที่มีภาวะหน้าแดงตัวแดง กระหายน้ำ ขี้ร้อน หงุดหงิดง่าย',
    symptom_2_en: 'Reduce blood pressure for those with high body temperature',
    related_product_code_1: '2049',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 60,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'ความดันโลหิตสูง',
    symptom_1_en: 'High Blood pressure',
    symptom_2_th: 'ความโลหิตสูง',
    symptom_2_en: 'For those with high blood pressure',
    related_product_code_1: '4008',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 61,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'ความดันโลหิตสูง',
    symptom_1_en: 'High Blood pressure',
    symptom_2_th: 'ความโลหิตสูง',
    symptom_2_en: 'For those with high blood pressure',
    related_product_code_1: '2037',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 62,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'น้ำตาลในเลือดสูง',
    symptom_1_en: 'High blood sugar',
    symptom_2_th: 'น้ำตาลในเลือดสูง',
    symptom_2_en: 'For those with high blood sugar',
    related_product_code_1: '2046',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 63,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'น้ำตาลในเลือดสูง',
    symptom_1_en: 'High blood sugar',
    symptom_2_th:
      'น้ำตาลในเลือดสำหรับผู้ที่เป็นเบาหวานและเป็นไข้ ร้อนใน กระหายน้ำบ่อย',
    symptom_2_en: 'Reduce blood sugar for those with diabetes, with fever',
    related_product_code_1: '2045',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 64,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'น้ำตาลในเลือดสูง',
    symptom_1_en: 'High blood sugar',
    symptom_2_th: 'น้ำตาลสำหรับผู้ที่มีปัญหาอาหารไม่ย่อยหรือท้องอืดร่วมด้วย',
    symptom_2_en: 'Reduce bloodsugar for those with constipation',
    related_product_code_1: '2044',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 65,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'น้ำตาลในเลือดสูง',
    symptom_1_en: 'High blood sugar',
    symptom_2_th: 'น้ำตาลในเลือดสำหรับผู้ที่น้ำตาลในเลือดไม่สูงมาก',
    symptom_2_en: 'Reduce slightly high blood sugar',
    related_product_code_1: '2047',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 66,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'น้ำตาลในเลือดสูง',
    symptom_1_en: 'High blood sugar',
    symptom_2_th: 'น้ำตาลในเลือดสำหรับผู้ที่น้ำตาในเลือดไม่สูงมาก',
    symptom_2_en: 'Reduce slightly high blood sugar',
    related_product_code_1: '4008',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 67,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'น้ำตาลในเลือดสูง',
    symptom_1_en: 'High blood sugar',
    symptom_2_th: 'น้ำตาลในเลือดสำหรับผู้ที่น้ำตาในเลือดไม่สูงมาก',
    symptom_2_en: 'Reduce slightly high blood sugar',
    related_product_code_1: '2037',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 68,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'ไขมันในเลือดสูง',
    symptom_1_en: 'Cholesterol',
    symptom_2_th: 'ไขมันในเลือดสูง',
    symptom_2_en: 'Reduce cholesterol',
    related_product_code_1: '2042',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 69,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'ไขมันในเลือดสูง',
    symptom_1_en: 'Cholesterol',
    symptom_2_th: 'ไขมันในเลือดสูง เพิ่มไขมันที่ดี',
    symptom_2_en: 'Reduce cholesterol',
    related_product_code_1: '2041',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 70,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'ไขมันพอกตับ',
    symptom_1_en: 'Fatty liver',
    symptom_2_th: 'ไขมันในเลือดสูง ไขมันพอกตับ',
    symptom_2_en: 'Reduce cholesterol, fatty liver',
    related_product_code_1: '2030',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 71,
    symptom_id: 5,
    body_system_th: 'เบาหวาน ไขมัน ความดัน',
    body_system_en: 'High Blood pressure , Diabetes , Cholesterol',
    symptom_1_th: 'ไขมันพอกตับ',
    symptom_1_en: 'Fatty liver',
    symptom_2_th: 'ตับอักเสบ ตับแข็ง หรือไขมันพอกตับ',
    symptom_2_en: 'Cirrhosis, hepatitis, fatty liver',
    related_product_code_1: '2040',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 72,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ขับน้ำคาวปลา',
    symptom_1_en: 'Relieve menstruation disorders',
    symptom_2_th: 'ขับน้ำคาวปลา',
    symptom_2_en: 'Relieve menstruation disorders',
    related_product_code_1: '2032',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 73,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ขับน้ำนม',
    symptom_1_en: 'Lactation',
    symptom_2_th: 'ขับน้ำนม',
    symptom_2_en: 'Improve lactation for breastfeeding mothers',
    related_product_code_1: '2031',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 74,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'บำรุงน้ำนม',
    symptom_1_en: 'Lactation',
    symptom_2_th: 'บำรุงน้ำนม',
    symptom_2_en: 'Improve lactation for breastfeeding mothers',
    related_product_code_1: '2029',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 75,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ร้อนวูบวาบวัยทอง',
    symptom_1_en: 'Menopausal symptoms',
    symptom_2_th: 'ร้อนวูบวาบวัยทอง',
    symptom_2_en: 'Hot flashes',
    related_product_code_1: '2028',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 76,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ร้อนวูบวาบวัยทอง',
    symptom_1_en: 'Menopausal symptoms',
    symptom_2_th: 'ร้อนวูบวาบ  ประจำเดือนมาไม่ปกติ',
    symptom_2_en: 'Hot flashes,menstruation disorders',
    related_product_code_1: '2032',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 77,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ร้อนวูบวาบวัยทอง',
    symptom_1_en: 'Menopausal symptoms',
    symptom_2_th: 'ร้อนวูบวาบ  ประจำเดือนมาไม่ปกติ',
    symptom_2_en: 'Hot flashes,menstruation disorders',
    related_product_code_1: '2026',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 78,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ร้อนวูบวาบวัยทอง',
    symptom_1_en: 'Menopausal symptoms',
    symptom_2_th: 'ร้อนวูบวาบ  ประจำเดือนมาไม่ปกติ',
    symptom_2_en: 'Hot flashes,menstruation disorders',
    related_product_code_1: '2051',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 79,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ร้อนวูบวาบวัยทอง',
    symptom_1_en: 'Menopausal symptoms',
    symptom_2_th:
      'ร้อนวูบวาบวัยทอง สูญเสียมวลกระดูกมาก กระดูกเริ่มพรุนเริ่มบาง',
    symptom_2_en: 'Menopause,osteoporosis, weakened bones',
    related_product_code_1: '2027',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 80,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ประจำเดือนไม่ปกติ',
    symptom_1_en: 'Irregular period/ menstruation',
    symptom_2_th:
      'ใช้ในการคลอดบุตรแล้ว ไม่ต้องอยู่ไฟ บำรุงโลหิต เจริญอาหาร บำรุงน้ำนม',
    symptom_2_en:
      'For ladies who just gave birth, blood tonic, increase appetite, lactation',
    related_product_code_1: '5007',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 81,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ประจำเดือนไม่ปกติ',
    symptom_1_en: 'Irregular period/ menstruation',
    symptom_2_th:
      'ยาตำรับจีนโบราณ มีตังกุยเป็นส่วนประกอบ บำรุงร่างกายสตรี แก้ประจำเดือนไม่ปกติ บำรุงโลหิต',
    symptom_2_en: 'For ladies with irregular menstruation, blood tonic',
    related_product_code_1: '5006',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 82,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ประจำเดือนไม่ปกติ',
    symptom_1_en: 'Irregular period/ menstruation',
    symptom_2_th:
      'ยาตำรับจีนโบราณ มีตังกุยเป็นส่วนประกอบ บำรุงร่างกายสตรี แก้ประจำเดือนไม่ปกติ บำรุงโลหิต',
    symptom_2_en: 'For ladies with irregular menstruation, blood tonic',
    related_product_code_1: '5005',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 83,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ประจำเดือนไม่ปกติ',
    symptom_1_en: 'Irregular period/ menstruation',
    symptom_2_th:
      'ยาตำรับจีนโบราณ มีโสมเป็นส่วนประกอบ บำรุงร่างกายสตรี แก้ประจำเดือนไม่ปกติ บำรุงโลหิต เจริญอาหาร',
    symptom_2_en:
      'For ladies with irregular menstruation, blood tonic, increase appetite',
    related_product_code_1: '5004',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 84,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ประจำเดือนไม่ปกติ',
    symptom_1_en: 'Irregular period/ menstruation',
    symptom_2_th:
      'บำรุงร่างกายสตรี แก้ประจำเดือนไม่ปรกติ บำรุงโลหิต เจริญอาหาร',
    symptom_2_en:
      'For ladies with irregular menstruation, blood tonic, increase appetite',
    related_product_code_1: '2032',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 85,
    symptom_id: 7,
    body_system_th: 'บำรุงสุขภาพสตรี',
    body_system_en: "Women's health",
    symptom_1_th: 'ไข้ทับระดู',
    symptom_1_en: 'Fever during menstruation',
    symptom_2_th: 'ประจำเดือนมาไม่ปกติ มีไข้ทับระดู',
    symptom_2_en: 'Irregular menstruation cycle, with fever',
    related_product_code_1: '2026',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 86,
    symptom_id: 8,
    body_system_th: 'บำรุงสุขภาพบุรุษ',
    body_system_en: "Men's health",
    symptom_1_th: 'เสริมสมรรถภาพชาย',
    symptom_1_en: 'Tonic for men',
    symptom_2_th: 'บำรุงกำลัง แก้ปวดเมื่อย',
    symptom_2_en: 'Tonic , relieve muscular pain',
    related_product_code_1: '2011',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 87,
    symptom_id: 8,
    body_system_th: 'บำรุงสุขภาพบุรุษ',
    body_system_en: "Men's health",
    symptom_1_th: 'เสริมสมรรถภาพชาย',
    symptom_1_en: 'Tonic for men',
    symptom_2_th: 'เสริมสมรรถภาพ',
    symptom_2_en: 'Tonic for men',
    related_product_code_1: '2024',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 88,
    symptom_id: 9,
    body_system_th: 'ควบคุมน้ำหนัก',
    body_system_en: 'control weight',
    symptom_1_th: 'ลดการดูดซึมแป้ง',
    symptom_1_en: 'Reduce carbohydrate absorption',
    symptom_2_th: 'ควบคุมน้ำหนักในผู้ที่ต้องการลดแป้ง',
    symptom_2_en:
      'Weight control for those who want to reduce carbohydrate intake',
    related_product_code_1: '2022',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 89,
    symptom_id: 9,
    body_system_th: 'ควบคุมน้ำหนัก',
    body_system_en: 'control weight',
    symptom_1_th: 'ลดการดูดซึมแป้ง',
    symptom_1_en: 'Reduce carbohydrate absorption',
    symptom_2_th: 'ควบคุมน้ำหนักในผู้ที่ต้องการลดแป้ง',
    symptom_2_en:
      'Weight control for those who want to reduce carbohydrate intake',
    related_product_code_1: '2023',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 90,
    symptom_id: 9,
    body_system_th: 'ควบคุมน้ำหนัก',
    body_system_en: 'control weight',
    symptom_1_th: 'ลดการดูดซึมแป้ง',
    symptom_1_en: 'Reduce carbohydrate absorption',
    symptom_2_th: 'ควบคุมน้ำหนักในผู้ที่ต้องการลดแป้ง',
    symptom_2_en:
      'Weight control for those who want to reduce carbohydrate intake',
    related_product_code_1: '2021',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 91,
    symptom_id: 9,
    body_system_th: 'ควบคุมน้ำหนัก',
    body_system_en: 'control weight',
    symptom_1_th: 'ช่วยเผาผลาญ',
    symptom_1_en: 'Metabolism',
    symptom_2_th: 'ช่วยเสริมระบบเผาผลาญร่างกาย',
    symptom_2_en: 'Increase metabolism',
    related_product_code_1: '2023',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 92,
    symptom_id: 9,
    body_system_th: 'ควบคุมน้ำหนัก',
    body_system_en: 'control weight',
    symptom_1_th: 'ช่วยเผาผลาญ',
    symptom_1_en: 'Metabolism',
    symptom_2_th:
      'ช่วยเสริมระบบเผาผลาญร่างกาย ลดการดูดซึมแป้งและไขมันเกินความจำเป็น',
    symptom_2_en: 'Increase metabolism',
    related_product_code_1: '2022',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 93,
    symptom_id: 10,
    body_system_th: 'บำรุงสมอง',
    body_system_en: 'Brain health',
    symptom_1_th: 'มีความเครียด คิดมาก',
    symptom_1_en: 'Mental stress',
    symptom_2_th: 'เหมาะกับคนที่มีความเครียด มีใบหน้าแดง พักผ่อนน้อย',
    symptom_2_en: 'For those with mental stress, and insufficient rest',
    related_product_code_1: '2048',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 94,
    symptom_id: 10,
    body_system_th: 'บำรุงสมอง',
    body_system_en: 'Brain health',
    symptom_1_th: 'เลือดไหลเวียนไม่ดี',
    symptom_1_en: 'Irregular blood flow in brain',
    symptom_2_th:
      'เหมาะกับคนที่สมองขาดเลือดหรือเลือดไหลเวียนไม่ดี เส้นเลือดตีบ ไขมันอุดตัน มีเส้นเลือดขอดมาก',
    symptom_2_en: 'For those with low blood circulation, and blocked arteries',
    related_product_code_1: '2004',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 95,
    symptom_id: 10,
    body_system_th: 'บำรุงสมอง',
    body_system_en: 'Brain health',
    symptom_1_th: 'เสริมสร้างเซลล์ประสาท',
    symptom_1_en: 'Neurological health',
    symptom_2_th: 'เหมาะกับผู้ที่ต้องรับประทานยาละลายลิ่มเลือด',
    symptom_2_en:
      'Suitable for people who take medication preventing blood clot, but want to improve meurological health',
    related_product_code_1: '2016',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 96,
    symptom_id: 10,
    body_system_th: 'บำรุงสมอง',
    body_system_en: 'Brain health',
    symptom_1_th: 'นอนไม่สนิท หลับๆตื่นๆ',
    symptom_1_en: 'insomnia',
    symptom_2_th: 'นอนไม่สนิท หลับๆตื่นๆ',
    symptom_2_en: 'insomnia',
    related_product_code_1: '4001',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 97,
    symptom_id: 10,
    body_system_th: 'บำรุงสมอง',
    body_system_en: 'Brain health',
    symptom_1_th: 'นอนไม่สนิท หลับๆตื่นๆ',
    symptom_1_en: 'insomnia',
    symptom_2_th: 'นอนไม่หลับ',
    symptom_2_en: 'insomnia',
    related_product_code_1: '2057',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 98,
    symptom_id: 11,
    body_system_th: 'ปัญหาดวงตา',
    body_system_en: 'Eye health',
    symptom_1_th: 'ตาพร่า ตามัว',
    symptom_1_en: 'Blurry eyes',
    symptom_2_th: 'ตาพร่า ตามัว ต้องการบำรุงสายตา ป้องกันไม่ให้เกิดต้อตา',
    symptom_2_en: 'Blurry eyes, may reduce risk of having cataracts',
    related_product_code_1: '2014',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 99,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'ร่างกายผอมแห้ง',
    symptom_1_en: 'Lethargic body, loss of body mass',
    symptom_2_th: 'เหมาะสำหรับคนที่มีร่างกายผอมแห้ง ไม่มีกำลัง เบื่ออาหาร',
    symptom_2_en:
      'Suitable for people with rapid weight loss, and sudden decrease in appetite',
    related_product_code_1: '2013',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 100,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'ร่างกายผอมแห้ง',
    symptom_1_en: 'Lethargic body, loss of body mass',
    symptom_2_th: 'เหมาะสำหรับคนที่มีร่างกายผอมแห้ง ไม่มีกำลัง เบื่ออาหาร',
    symptom_2_en:
      'Suitable for people with rapid weight loss, and sudden decrease in appetite',
    related_product_code_1: '2056',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 101,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'พักผ่อนไม่เพียงพอ',
    symptom_1_en: 'Lethargic body',
    symptom_2_th: 'บำรุงกำลัง',
    symptom_2_en: 'Health tonic, increase energy',
    related_product_code_1: '2003',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 102,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'พักผ่อนไม่เพียงพอ',
    symptom_1_en: 'Lethargic body',
    symptom_2_th: 'บำรุงกำลัง',
    symptom_2_en: 'Health tonic, increase energy',
    related_product_code_1: '2010',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 103,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'พักผ่อนไม่เพียงพอ',
    symptom_1_en: 'Lethargic body',
    symptom_2_th: 'บำรุงกำลัง',
    symptom_2_en: 'Health tonic, increase energy',
    related_product_code_1: '2037',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 104,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'เพิ่มการสูบฉีดเลือดลม',
    symptom_1_en: 'Blood circulation',
    symptom_2_th: 'บำรุงกำลัง ลดอาการอ่อนเพลีย เพิ่มพลังงานเผาผลาญในร่างกาย',
    symptom_2_en: 'Health tonic, reduce fatigue',
    related_product_code_1: '2011',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 105,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'เสริมกำลังและสมรรถภาพ',
    symptom_1_en: 'Energy',
    symptom_2_th: 'ปวดเมื่อย บำรุงกำลัง เพิ่มสมรรถภาพ กระตุ้นเลือดลม',
    symptom_2_en: 'Relieve muscle ache, increase energy',
    related_product_code_1: '2010',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 106,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'บำรุงกำลัง',
    symptom_1_en: 'Tonic',
    symptom_2_th: 'เร่งกระบวนการเมตาบอลิสซึมต่างๆ เพื่อปลดปล่อยพลังงาน',
    symptom_2_en: 'Increase metabolism, increase energy',
    related_product_code_1: '4012',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 107,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'บำรุงกำลัง',
    symptom_1_en: 'Tonic',
    symptom_2_th: 'ยาตำรับจีนโบราณ บำรุงร่างกาย บรรเทาอาการอ่อนเพลีย',
    symptom_2_en: 'Health tonic, reduce fatigue',
    related_product_code_1: '5003',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 108,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'บำรุงกำลัง',
    symptom_1_en: 'Tonic',
    symptom_2_th: 'ยาตำรับจีนโบราณ บำรุงร่างกาย บรรเทาอาการอ่อนเพลีย',
    symptom_2_en: 'Health tonic, reduce fatigue',
    related_product_code_1: '5002',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 109,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'บำรุงกำลัง',
    symptom_1_en: 'Tonic',
    symptom_2_th:
      'ตำรับจีนโบราณ มีโสมเป็นส่วนประกอบ บรรเทาอาการอ่อนเพลีย บำรุงร่างกาย',
    symptom_2_en: 'Reduce fatigue',
    related_product_code_1: '5001',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 110,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'บำรุงกำลัง',
    symptom_1_en: 'Tonic',
    symptom_2_th: 'บำรุงร่างกาย บำรุงโลหิต เจริญอาหาร',
    symptom_2_en: 'Health tonic, blood tonic, increase appetite',
    related_product_code_1: '2009',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 111,
    symptom_id: 12,
    body_system_th: 'บำรุงกำลัง',
    body_system_en: 'Tonic',
    symptom_1_th: 'ซีด โลหิตจาง',
    symptom_1_en: 'blood tonic',
    symptom_2_th: 'มีภาวะซีด เป็นโรคโลหิตจาง เป็นธาลัสซีเมีย',
    symptom_2_en: 'Pale, low red blood cells count',
    related_product_code_1: '2009',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 112,
    symptom_id: 13,
    body_system_th: 'ลดสารพิษในร่างกาย',
    body_system_en: 'Detoxification',
    symptom_1_th: 'ติดสุราเรื้อรัง',
    symptom_1_en: 'Alcoholism, heavy drinkers',
    symptom_2_th: 'ติดสุราเรื้อรัง โดนสารพิษ ติดยาเสพติดชนิดแอมเฟตามีน',
    symptom_2_en: 'Blood intoxification, alcohol intoxification',
    related_product_code_1: '2008',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 113,
    symptom_id: 14,
    body_system_th: 'บำรุงตับ',
    body_system_en: 'liver care',
    symptom_1_th: 'บำรุงตับ',
    symptom_1_en: 'liver care',
    symptom_2_th: 'ตับอักเสบ ตับแข็ง หรือไขมันพอกตับ',
    symptom_2_en: 'Hepatitis, cirrhosis, and fatty liver',
    related_product_code_1: '2040',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
  {
    id: 114,
    symptom_id: 14,
    body_system_th: 'บำรุงตับ',
    body_system_en: 'liver care',
    symptom_1_th: 'ไวรัสตับอักเสบ',
    symptom_1_en: 'liver care',
    symptom_2_th: 'ไวรัสตับอักเสบ ตับอักเสบ กระตุ้นการทำงานตับอ่อน',
    symptom_2_en: 'Hepatitis, Improves pancreatic function',
    related_product_code_1: '2007',
    related_product_code_2: '',
    related_product_code_3: '',
    related_product_code_4: '',
    medicine_name_en: '',
  },
];


