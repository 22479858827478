import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { color } from '../../Layouts/theme';

interface BodyPartProps {
  bodyImage: any;
  dataAos: string | 'fade-right';
  boxStyled?: CSSProperties;
  alt?: string;
}

const ImageBodyBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.mainColor5};
  border-radius: 0px 110px;
  padding: 50px;
  @media (max-width: 576px) {
    border-radius: 0px 80px;
  }
`;

const ImageBody = styled.img`
  width: inherit;
  height: 480px;
  @media (mix-width: 576px) {
    height: 590px;
  }
`;

const BodyPart: React.FC<BodyPartProps> = ({
  bodyImage,
  dataAos,
  boxStyled,
  alt,
}) => (
  <ImageBodyBox data-aos={dataAos} style={boxStyled}>
    <ImageBody alt={alt} src={bodyImage} />
  </ImageBodyBox>
);

export default BodyPart;
