import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useMst } from '@/models/root-store';
import i18next from 'i18next';
import { Parallax, Background } from 'react-parallax';

import { images, spacing, color } from '../../Layouts/theme';
import {
  NON_PADDING_LEFT_RIGHT,
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_TH,
  HEADER_EN,
  BODY_2_TH,
  BODY_2_EN,
} from '../../Layouts/styled/main';
// import SystemButton from './system-button';
import SystemButton, { SystemItemsProps } from '../system-button/system-button';
import GastrointestinalSystem from './gastrointestinal-system';
import SymptomList from './symptom-list';
import { products } from '../../db/products';
import { symptomDetail, symptoms } from '../../db/symptoms';
import BodyPart from '../body-part/body-part';

const LINE: CSSProperties = {
  borderBottom: `1.5px solid ${color.highlight}`,
};

const LIST_OF_PRODUCT: CSSProperties = {
  padding: '12px 12px 12px 0',
  // borderTop: `1px solid ${color.darkGray}`,
  // borderBottom: `1.5px solid ${color.darkGray}`,
};

const getUniqueListBy = (arr: Array<any>, key: string): Array<any> => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

const distinct = (value: any, index: any, self: any): any => {
  return self.indexOf(value) === index;
};

interface DiagnosisProps {
  data?: any
}

const Diagnosis: React.FC<DiagnosisProps> = ({ data }) => {
  const [bodyImage, setBodyImage] = useState<string>(null);
  const [menuActive, setMenuActive] = useState<number>(1);
  const [menuActiveName, setMenuActiveName] = useState<string | null>(null);
  const [isShow, setIsShow] = useState<boolean>(true);
  const [symptomData, setSymptomData] = useState<Array<any>>(null);
  const [symptomList, setSymptomList] = useState<Array<any>>(null);
  const [relateProducts, setRelateProducts] = useState<Array<any>>(null);
  const [symptomIdActive, setSymptomIdActive] = useState<number>(null);
  const [showProduct, setShowProduct] = useState<boolean>(false);
  const [largeScreen, setLargeScreen] = useState<boolean>(true);
  // const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { ouayUnStore } = useMst();
  const symptomActionRefMobile = useRef<any>(null);
  const symptomActionRefDesktop = useRef<any>(null);

  const executeScroll = (isMobile: boolean) => {
    if (isMobile) {
      if (!symptomActionRefMobile.current) return;
      symptomActionRefMobile.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      if (!symptomActionRefDesktop.current) return;
      symptomActionRefDesktop.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (ouayUnStore?.screenSize?.width < 1280) {
      setLargeScreen(false);
    }
    setBodyImage(symptoms[0].image_name);

    return () => {
      setBodyImage(null);
    };
  }, []);

  useEffect(() => {
    if (symptomList && symptomList[0]) {
      setMenuActiveName(
        i18next?.language === 'th'
          ? symptomList[0].body_system_th
          : symptomList[0].body_system_en,
      );
    }
  }, [symptomList?.length, i18next?.language]);

  useEffect(() => {
    if (menuActive && ouayUnStore?.screenSize?.width) {
      const symptomData = symptomDetail.filter(
        (symptom) => symptom.symptom_id === menuActive,
      );
      setSymptomData(symptomData);
      const list = getUniqueListBy(symptomData, 'symptom_1_th');
      setSymptomList(list);
      const productCode = [];
      symptomData
        .filter((symptom) => symptom.symptom_1_th === list[0].symptom_1_th)
        .forEach((d) => {
          if (d.related_product_code_1)
            productCode.push(d.related_product_code_1);
          if (d.related_product_code_2)
            productCode.push(d.related_product_code_2);
          if (d.related_product_code_3)
            productCode.push(d.related_product_code_3);
          if (d.related_product_code_4)
            productCode.push(d.related_product_code_4);
        });
      const uniqueProductCode = productCode.filter(distinct);
      const relateProductList = uniqueProductCode.map((productCode) =>
        products.filter((prod) => prod.product_code === productCode),
      );
      const mergeProduct = relateProductList
        .flat(1)
        .reduce(function (rows, key, index) {
          return (
            (index % (ouayUnStore.screenSize.width <= 767 ? 2 : 3) == 0
              ? rows.push([key])
              : rows[rows.length - 1].push(key)) && rows
          );
        }, []);

      setRelateProducts(mergeProduct);
      setSymptomIdActive(list[0].id);
    }
  }, [menuActive, ouayUnStore?.screenSize?.width]);

  const onToggle = (data: any) => {
    setBodyImage(data.imageName);
    setMenuActive(data.id);
    setMenuActiveName(i18next?.language === 'th' ? data.nameTH : data.nameEN);
    setIsShow(!isShow);
    setShowProduct(true);
    // setIsOpen(false);
    executeScroll(ouayUnStore.screenSize.width <= 767);
  };

  const onHandleList = (data: any) => {
    setSymptomList(data);
  };

  const onToggleSymptomList = (data: any) => {
    setSymptomIdActive(data.id);
    const productCode = [];
    symptomData
      .filter((symptom) => symptom.symptom_1_th === data.symptom_1_th)
      .forEach((d) => {
        if (d.related_product_code_1)
          productCode.push(d.related_product_code_1);
        if (d.related_product_code_2)
          productCode.push(d.related_product_code_2);
        if (d.related_product_code_3)
          productCode.push(d.related_product_code_3);
        if (d.related_product_code_4)
          productCode.push(d.related_product_code_4);
      });
    const uniqueProductCode = productCode.filter(distinct);
    const relateProductList = uniqueProductCode.map((productCode) =>
      products.filter((prod) => prod.product_code === productCode),
    );
    const mergeProduct = relateProductList
      .flat(1)
      .reduce((rows, key, index) => {
        return (
          (index % (ouayUnStore.screenSize.width <= 767 ? 2 : 3) == 0
            ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows
        );
      }, []);
    setRelateProducts(mergeProduct);
    onHandleList(symptomList);
    setShowProduct(true);
    // setIsOpen(false);
  };

  const onHandleGoBack = () => {
    setIsShow(!isShow);
  };

  const BACK_BUTTON: CSSProperties = {
    ...(i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN),
    fontSize: i18next?.language === 'th' ? 15 : 14,
    position: 'absolute',
    padding: '47px 0 0',
    top: -35,
    marginLeft: -15,
  };

  return (
    <section>
      {/* //? for screen size xs-md */}
      <Container fluid className="d-md-none d-block">
        <Row
          className="justify-content-center"
          style={{ paddingTop: 40, paddingBottom: 40 }}
        >
          <Col xs="10" sm="8" style={{ maxWidth: 650 }}>
            <div data-aos="fade-up">
              <div
                dangerouslySetInnerHTML={{
                  __html: t('virtual_doctor_title'),
                }}
                style={
                  i18next?.language === 'th'
                    ? MOBILE_HEADER_TH
                    : MOBILE_HEADER_EN
                }
              />
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: t('virtual_doctor_description'),
                }}
                style={i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <BodyPart
              bodyImage={
                !bodyImage
                  ? images.defaultBody
                  : require(`../../images/body/${bodyImage}`).default
              }
              dataAos="fade-right"
              alt="body part"
            />
          </Col>
        </Row>
        <Row
          ref={symptomActionRefMobile}
          className="justify-content-center"
          style={{
            paddingTop: 40,
            paddingBottom: isShow ? spacing[7] : spacing[5],
          }}
        >
          <Col xs="10" sm="8" style={NON_PADDING_LEFT_RIGHT}>
            <Container fluid>
              <Row style={{ ...(!isShow && { paddingTop: 50 }) }}>
                {!isShow && (
                  <Button style={BACK_BUTTON} onClick={onHandleGoBack}>
                    {t('back')}
                  </Button>
                )}
                {isShow ? (
                  <SystemButton
                    items={symptoms.map((v) => ({
                      id: v.id,
                      nameTH: v.body_system_th,
                      nameEN: v.body_system_en,
                      imageName: v.image_name,
                    }))}
                    isActived={false}
                    menuActive={menuActive}
                    screenSize="mb"
                    onToggle={onToggle}
                  />
                ) : (
                  <div style={{ width: '100%' }}>
                    <div
                      dangerouslySetInnerHTML={{ __html: menuActiveName }}
                      style={
                        i18next?.language === 'th'
                          ? MOBILE_HEADER_TH
                          : MOBILE_HEADER_EN
                      }
                    />
                    <div
                      style={{
                        paddingTop: spacing[5],
                        paddingBottom: !showProduct ? spacing[5] : undefined,
                      }}
                    >
                      <div
                        style={LIST_OF_PRODUCT}
                      // onClick={() => setIsOpen(!isOpen)}
                      >
                        <span color="primary" style={{ paddingLeft: 3 }}>
                          {t('listOfProduct')}
                        </span>
                      </div>
                      <Collapse in={true}>
                        <div id="symptom-list-collapse">
                          <SymptomList
                            symptomList={symptomList}
                            endOfProduct={2}
                            symptomIdActive={symptomIdActive}
                            onToggle={onToggleSymptomList}
                          />
                        </div>
                      </Collapse>
                    </div>
                  </div>
                )}
              </Row>
            </Container>
          </Col>
        </Row>

        {showProduct && !isShow && (
          <Row style={{ paddingBottom: spacing[7] }}>
            <Col xs="1" />
            <Col xs={'10'} style={NON_PADDING_LEFT_RIGHT}>
              <Container
                fluid
                style={{
                  ...NON_PADDING_LEFT_RIGHT,
                  display: 'grid',
                  alignContent: 'space-between',
                  height: '100%',
                }}
                data-aos="fade-up"
              >
                <GastrointestinalSystem
                  symptomList={symptomList}
                  symptomIdActive={symptomIdActive}
                  relateProducts={relateProducts}
                  endOfProduct={2}
                  data={data}
                  onToggle={onToggleSymptomList}
                />
              </Container>
            </Col>
            <Col xs="1" />
          </Row>
        )}
      </Container>

      {/* //? for screen size md++ */}
      <Container fluid className="d-none d-md-block">
        <Row className="justify-content-center">
          <Col md={12} style={{ ...NON_PADDING_LEFT_RIGHT, maxWidth: 1080 }}>
            <Container fluid>
              <Row
                style={{
                  paddingBottom: spacing[5],
                  ...(largeScreen
                    ? { paddingLeft: 15, paddingRight: 15 }
                    : undefined),
                  justifyContent: 'space-between',
                }}
              >
                <Col
                  md={{
                    span: largeScreen ? 5 : 4,
                    offset: largeScreen ? 0 : 1,
                  }}
                  style={NON_PADDING_LEFT_RIGHT}
                >
                  <Container
                    fluid
                    style={{
                      ...NON_PADDING_LEFT_RIGHT,
                      display: 'grid',
                      alignContent: 'space-between',
                      height: '100%',
                    }}
                    data-aos="fade-up"
                  >
                    <Row style={{ paddingTop: spacing[7] }}>
                      <Col lg={10} md="10">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('virtual_doctor_title'),
                          }}
                          style={
                            i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                          }
                        />
                        <br />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('virtual_doctor_description'),
                          }}
                          style={
                            i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                          }
                        />
                      </Col>
                      <Col lg={2} md="2" />
                    </Row>
                    <br />
                    <Row>
                      <SystemButton
                        items={symptoms.map((v) => ({
                          id: v.id,
                          nameTH: v.body_system_th,
                          nameEN: v.body_system_en,
                          imageName: v.image_name,
                        }))}
                        menuActive={menuActive}
                        screenSize="dt"
                        onToggle={onToggle}
                      />
                    </Row>
                  </Container>
                </Col>
                <Col
                  md={{ span: 6, offset: 1 }}
                  style={{
                    ...NON_PADDING_LEFT_RIGHT,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <BodyPart
                    bodyImage={
                      !bodyImage
                        ? images.defaultBody
                        : require(`../../images/body/${bodyImage}`).default
                    }
                    dataAos="fade-right"
                    alt="body part"
                    boxStyled={{ maxWidth: 480 }}
                  />
                </Col>
              </Row>

              <Row
                ref={symptomActionRefDesktop}
                style={{ paddingBottom: spacing[8] + 10 }}
              />

              <Row
                style={{
                  ...(largeScreen
                    ? { paddingLeft: 15, paddingRight: 15 }
                    : undefined),
                }}
              >
                <Col
                  md={{
                    span: largeScreen ? 12 : 10,
                    offset: largeScreen ? 0 : 1,
                  }}
                  style={NON_PADDING_LEFT_RIGHT}
                >
                  <Container
                    fluid
                    style={{
                      ...NON_PADDING_LEFT_RIGHT,
                      display: 'grid',
                      alignContent: 'space-between',
                      height: '100%',
                    }}
                    data-aos="fade-up"
                  >
                    <Row style={{ ...LINE, paddingBottom: spacing[3] }}>
                      <Col>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: menuActiveName,
                          }}
                          style={
                            i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                          }
                        />
                      </Col>
                    </Row>
                    <GastrointestinalSystem
                      symptomList={symptomList}
                      symptomIdActive={symptomIdActive}
                      relateProducts={relateProducts}
                      endOfProduct={3}
                      data={data}
                      onToggle={onToggleSymptomList}
                    />
                  </Container>
                </Col>
                {!largeScreen && <Col md="1" />}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Diagnosis;
