import React from 'react';
import Diagnosis from '../components/virtual-doctor/diagnosis';
import {
  SpaceBetweenContent,
  SPACE_BETWEEN_CONTENT,
} from '../Layouts/styled/main';
import EndingContent from '../Layouts/Footer/ending-content';
import { graphql, PageProps } from 'gatsby';

interface GraphqlData {
  edges: any
}

const VirtualDoctor: React.FC<PageProps<{ allFile: GraphqlData }>> = ({ data }) => {
  return (
    <main className={'main'}>
      <Diagnosis data={data.allFile.edges} />

      <div style={SPACE_BETWEEN_CONTENT} className="d-none d-md-block" />
      <EndingContent />

      <SpaceBetweenContent className="d-md-none d-block" />
    </main>
  );
};
export default VirtualDoctor;

export const query = graphql`
  query {
    allFile(filter: {relativePath: {regex: "/product/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 335, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
